import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import "./textnsize.css";
import {  Container} from "react-bootstrap";

import { red } from '@mui/material/colors';

export default function NeedHelp(){
    return(
        <Container>
        <h1 className="sub-heading-dark text-left" >Need Assistance<ContactSupportIcon fontSize="small" sx={{ color: red[700] }}/><span className="sub-heading-light" >Click here for help</span></h1>
        </Container>
    )
}