import { Container } from "react-bootstrap";
import "./App.css";
import MainTabs from "./components/MainTabs";
import NeedHelp from "./components/NeedHelp";

function App() {
  return (
    <div className="App">
      <Container className="container">
        <div className="inside-container">
          <MainTabs />
          <NeedHelp />
        </div>
      </Container>
    </div>
  );
}

export default App;
