import { useState, useRef, useEffect, useLayoutEffect } from "react";

import { Tab, Box } from "@material-ui/core";
import HeightWidth from "./HeightWidth";
import { TabList, TabPanel, TabContext } from "@material-ui/lab";
import FormatSizeIcon from "@mui/icons-material/FormatSize";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import TextFormatIcon from "@mui/icons-material/TextFormat";
import FormatPaintIcon from "@mui/icons-material/FormatPaint";
import VignetteIcon from "@mui/icons-material/Vignette";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { red, pink } from "@mui/material/colors";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Col, Container, Form, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import AttributionIcon from "@mui/icons-material/Attribution";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import { getTextWidth } from "get-text-width";
import TextRotationAngleupIcon from "@mui/icons-material/TextRotationAngleup";
import { CompactPicker } from "react-color";
import BrushIcon from "@mui/icons-material/Brush";
import BorderOuterIcon from "@mui/icons-material/BorderOuter";
import GradientIcon from "@mui/icons-material/Gradient";
import WbShadeIcon from "@mui/icons-material/WbShade";
import CircleIcon from "@mui/icons-material/Circle";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import FontPicker from "font-picker-react";
import { useTextWidth } from "@imagemarker/use-text-width";
import "./textnsize.css";
import CircleType from "circletype";
import curveDU from "../images/curve-d-u.png";
import curveD from "../images/curve-down.png";
import curveSL from "../images/curve-slant-left.png";
import curveSR from "../images/curve-slant-right.png";
import curveUD from "../images/curve-u-d.png";
import curveU from "../images/curve-up.png";
import reset from "../images/none.png";
import gi1 from "../images/graphics/1.png";
import gi2 from "../images/graphics/2.png";
import gi3 from "../images/graphics/3.png";
import gi4 from "../images/graphics/4.png";
import gi5 from "../images/graphics/5.png";
import gi6 from "../images/graphics/6.png";
import gi7 from "../images/graphics/7.png";
import gi8 from "../images/graphics/8.png";
import gi9 from "../images/graphics/9.png";
import gi10 from "../images/graphics/10.png";
import gi11 from "../images/graphics/11.png";
import gi12 from "../images/graphics/12.png";
import gi13 from "../images/graphics/13.png";
import gi14 from "../images/graphics/14.png";
import gi15 from "../images/graphics/15.png";
import gi16 from "../images/graphics/16.png";
import gi17 from "../images/graphics/17.png";
import gi18 from "../images/graphics/18.png";
import gi19 from "../images/graphics/19.png";
import gi20 from "../images/graphics/20.png";
import gi21 from "../images/graphics/21.png";
import gi22 from "../images/graphics/22.png";
import gi23 from "../images/graphics/23.png";
import gi24 from "../images/graphics/24.png";
import gi25 from "../images/graphics/25.png";
import gi26 from "../images/graphics/26.png";
import gi27 from "../images/graphics/27.png";
import gi28 from "../images/graphics/28.png";
import gi29 from "../images/graphics/29.png";
import gi30 from "../images/graphics/30.png";
import gi31 from "../images/graphics/31.png";
import gi32 from "../images/graphics/32.png";
import gi33 from "../images/graphics/33.png";
import gi34 from "../images/graphics/34.png";
import gi35 from "../images/graphics/35.png";
import gi36 from "../images/graphics/36.png";
import gi37 from "../images/graphics/37.png";
import gi38 from "../images/graphics/38.png";
import gi39 from "../images/graphics/39.png";
import gi40 from "../images/graphics/40.png";
import gi41 from "../images/graphics/41.png";
import gi42 from "../images/graphics/42.png";
import gi43 from "../images/graphics/43.png";
import gi44 from "../images/graphics/44.png";
import gi45 from "../images/graphics/45.png";
import gi46 from "../images/graphics/46.png";
import gi47 from "../images/graphics/47.png";
import gi48 from "../images/graphics/48.png";
import gi49 from "../images/graphics/49.png";
import gi50 from "../images/graphics/50.png";
import none from "../images/graphics/none.png";
import backGroundImg from "../images/bg-show-text.jpg";
import { width } from "@mui/system";

export default function MainTabs() {
  const [selectBgColor, setSelectBgColor] = useState("");

  const [alignment, setAlignment] = useState("normal");
  const [graphicsAlignment, setShowGraphicsAlignment] = useState("center");

  // const [showImages, setShowImages] = useState(false);
  const [showGraphics, setShowGraphics] = useState(false);
  const [grclr, setgrclr] = useState(false);
  const [italic, setItalic] = useState("normal");
  const [bold, setBold] = useState("normal");
  const [value, setValue] = useState("1");
  const [activeFontFamily, setFont] = useState("Impact");
  const [sampleText, setSampleText] = useState("My Boat Name");
  const [sampleHtmlText, setSampleHtmlText] = useState("My Boat Name");
  const [fontColor, setFontColor] = useState("black");
  // const [fontid, setFontid] = useState("");
  const [borderColor, setBorderColor] = useState("");
  const [applyborderColor, setApplyborderColor] = useState(false);

  const [shadowColor, setShadowColor] = useState("black");

  const [gradientColor, setGradientColor] = useState("");

  const [shadow, setShadow] = useState("");
  const circleInstance = useRef(null);
  const priceInstance = useRef(null);
  const [skw, setSkw] = useState("0deg");
  const [Eff, setEff] = useState("0deg");
  const [img, setImg] = useState();
  const [circleRef, setCircleRef] = useState();
  const [circleRef2, setCircleRef2] = useState();
  const refwidth = useRef(null);
  const [price, setPrice] = useState();
  const [spacing, setSpacing] = useState("");

  const [widthInches, setWidthInches] = useState("");
  const [heightInches, setHeightInches] = useState("");
  const [imgRight, setImgRight] = useState();
  const [imgLeft, setImgLeft] = useState();
  const [imgBottom, setImgBottom] = useState();
  const [left, setLeft] = useState(false);
  const [right, setRight] = useState(false);
  const [bottom, setBottom] = useState(false);

  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
    // return size;
  }, []);
  // console.log(
  //   priceInstance,
  //   "------------------width inches--------------"
  // );
  useEffect(() => {
    setTimeout(() => {
      setWidthInches(priceInstance.current.offsetWidth / 48);
      setHeightInches(priceInstance.current.offsetHeight / 48);

      // setPrice(cost);
    }, 2000);
  }, [
    size,
    sampleText,
    activeFontFamily,
    bold,
    skw,
    Eff,
    circleRef,
    circleRef2,
    // fontid,
    sampleHtmlText,
    price,
  ]);
  const handleChangeBgColor = (event) => {
    setSelectBgColor(event.target.value);
  };
  const handleShadow = (currshadow) => {
    setShadow(currshadow);
  };
  const handleChangeFontColor = (color) => {
    setFontColor(color.hex);
    setgrclr(false);
  };

  const handleChangeBorderColor = (color) => {
    setBorderColor(color.hex);
    setApplyborderColor(true);
  };

  const handleChangeShadowColor = (color) => {
    setShadowColor(color.hex);
  };

  const handleChangeGradientColor = (color) => {
    setGradientColor(color.hex);
    setgrclr(true);
    setShowGraphics(false);
  };
  const changebold = () => {
    if (bold === "normal") {
      setBold("bold");
      setSpacing("2px");
    } else if (bold === "bold") {
      setBold("normal");
      setSpacing("");
    }
  };

  const changeitalic = () => {
    if (italic === "normal") {
      setItalic("italic");
      setSkw("0deg");
    } else if (italic === "italic") {
      setItalic("normal");
    }
  };
  const changeslantitalic = () => {
    if (skw === "0deg") {
      setSkw("20deg");
      setSampleText(sampleText);
      setItalic("normal");
    } else if (skw === "20deg") {
      setSkw("0deg");
    }
  };
  const handleChangeAlignment = (currAlignment) => {
    setAlignment(currAlignment);
  };
  const handleImgAlignment = (event) => {
    setShowGraphicsAlignment(event.target.value);
    // console.log(
    //   event.target.value,
    //   "-----------------Image Event Value---------------"
    // );
    // if (event.target.value === "top") {
    //   setImg(img);
    //   setBottom(false);
    //   setLeft(false);
    //   setRight(false);
    // } else if (event.target.value === "bottom") {
    //   // setImg(false);
    //   setImgBottom(img);
    //   setBottom(true);
    //   setLeft(false);
    //   setRight(false);
    // } else if (event.target.value === "left") {
    //   // setImg(false);
    //   // setImgBottom(false);
    //   setBottom(false);
    //   setLeft(true);
    //   setImgLeft(img);
    //   setRight(false);
    // } else if (event.target.value === "right") {
    //   // setImg(false);
    //   // setImgBottom(false);
    //   // setImgLeft(false);
    //   setBottom(false);
    //   setLeft(false);
    //   setImgRight(img);
    //   setRight(true);
    // }
    // setShowGraphicsAlignment(event.target.value);
  };
  // const handleImgAlignment = (event) => {
  //   setShowGraphicsAlignment(event.target.value);
  // };

  const changeInput = (event) => {
    var label = event.target.value.replace(/(?:\r\n|\r|\n)/g, "<br>");
    setSampleHtmlText(label);

    // sampleText = event.target.value.replace("\r\n","&lt;br&gt;");
    setSampleText(event.target.value);
  };

  // const keypressInput = (event) => {

  //   if(event.key === 'Enter'){
  //     event.preventDefault();
  //     sampleText = sampleText.replace('\r\n','&lt;br&gt;');
  //     setSampleText(sampleText) ;

  //   }
  // };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  // const onClickGraphicsButton = () => {
  //   if (!showImages) {
  //     setShowImages(true);
  //   } else {
  //     setShowImages(false);
  //   }
  // };

  const onImageClick = (img) => {
    // if (showGraphics == false) {
    setShowGraphics(true);
    setgrclr(false);
    setImg(img);

    setImgRight(img);
    setImgLeft(img);
    setImgBottom(img);
  };
  const showHideGraphicsHandler = () => {
    setShowGraphics(false);
  };
  const setBackgroundImageType = () => {
    if (grclr === true) {
      return `linear-gradient(${fontColor}, ${gradientColor})`;
    }
    // else if (showGraphics === true) {
    //   return `url(${img})`;
    // }
  };
  const handlefontChng = (aa) => {
    // setFontid(aa.id);
    // setSampleText(sampleText);
    console.log(
      aa,
      "--------------sdddddddddddddddddddd HAndle Font Change -------------------"
    );
    setFont(aa.family);
    // setSampleText(sampleText);
    // setSampleHtmlText(sampleHtmlText);
  };
  const setBackgroundImageGraphics = () => {
    if (showGraphics === true) {
      return `url(${img})`;
    }
  };
  const transpaerntImage = () => {};
  // const amount = "100";
  const ImgGraphics = () => (
    <div>
      <Row>
        <Col sm={1} onClick={showHideGraphicsHandler}>
          <img src={none} alt="gi1 " className="graphic-images" />
        </Col>
        <Col sm={1} onClick={() => onImageClick(gi1)}>
          <img src={gi1} alt="gi1 " className="graphic-images" />
        </Col>
        <Col sm={1} onClick={() => onImageClick(gi2)}>
          <img src={gi2} alt="gi3 " className="graphic-images" />
        </Col>
        <Col sm={1} onClick={() => onImageClick(gi3)}>
          <img src={gi3} alt="gi3 " className="graphic-images" />
        </Col>
        <Col sm={1} onClick={() => onImageClick(gi4)}>
          <img src={gi4} alt="gi1 " className="graphic-images" />
        </Col>
        <Col sm={1} onClick={() => onImageClick(gi5)}>
          <img src={gi5} alt="gi1 " className="graphic-images" />
        </Col>
        <Col sm={1} onClick={() => onImageClick(gi6)}>
          <img src={gi6} alt="gi1 " className="graphic-images" />
        </Col>
        <Col sm={1} onClick={() => onImageClick(gi7)}>
          <img src={gi7} alt="gi1 " className="graphic-images" />
        </Col>
        <Col sm={1} onClick={() => onImageClick(gi8)}>
          <img src={gi8} alt="gi1 " className="graphic-images" />
        </Col>
        <Col sm={1} onClick={() => onImageClick(gi9)}>
          <img src={gi9} alt="gi1 " className="graphic-images" />
        </Col>
        <Col sm={1} onClick={() => onImageClick(gi10)}>
          <img src={gi10} alt="gi1 " className="graphic-images" />
        </Col>
        <Col sm={1} onClick={() => onImageClick(gi11)}>
          <img src={gi11} alt="gi1 " className="graphic-images" />
        </Col>
      </Row>
      <Row>
        <Col sm={1} onClick={() => onImageClick(gi12)}>
          <img src={gi12} alt="gi1 " className="graphic-images" />
        </Col>
        <Col sm={1} onClick={() => onImageClick(gi13)}>
          <img src={gi13} alt="gi1 " className="graphic-images" />
        </Col>
        <Col sm={1} onClick={() => onImageClick(gi14)}>
          <img src={gi14} alt="gi3 " className="graphic-images" />
        </Col>
        <Col sm={1} onClick={() => onImageClick(gi15)}>
          <img src={gi15} alt="gi1 " className="graphic-images" />
        </Col>
        <Col sm={1} onClick={() => onImageClick(gi16)}>
          <img src={gi16} alt="gi1 " className="graphic-images" />
        </Col>
        <Col sm={1} onClick={() => onImageClick(gi17)}>
          <img src={gi17} alt="gi1 " className="graphic-images" />
        </Col>
        <Col sm={1} onClick={() => onImageClick(gi18)}>
          <img src={gi18} alt="gi1 " className="graphic-images" />
        </Col>
        <Col sm={1} onClick={() => onImageClick(gi19)}>
          <img src={gi19} alt="gi1 " className="graphic-images" />
        </Col>
        <Col sm={1} onClick={() => onImageClick(gi20)}>
          <img src={gi20} alt="gi1 " className="graphic-images" />
        </Col>
        <Col sm={1} onClick={() => onImageClick(gi21)}>
          <img src={gi21} alt="gi1 " className="graphic-images" />
        </Col>
        <Col sm={1} onClick={() => onImageClick(gi22)}>
          <img src={gi22} alt="gi1 " className="graphic-images" />
        </Col>
        <Col sm={1} onClick={() => onImageClick(gi23)}>
          <img src={gi23} alt="gi1 " className="graphic-images" />
        </Col>
      </Row>

      <Row>
        <Col sm={1} onClick={() => onImageClick(gi24)}>
          <img src={gi24} alt="gi1 " className="graphic-images" />
        </Col>
        <Col sm={1} onClick={() => onImageClick(gi25)}>
          <img src={gi25} alt="gi1 " className="graphic-images" />
        </Col>
        <Col sm={1} onClick={() => onImageClick(gi26)}>
          <img src={gi26} alt="gi3 " className="graphic-images" />
        </Col>
        <Col sm={1} onClick={() => onImageClick(gi27)}>
          <img src={gi27} alt="gi1 " className="graphic-images" />
        </Col>
        <Col sm={1} onClick={() => onImageClick(gi28)}>
          <img src={gi28} alt="gi1 " className="graphic-images" />
        </Col>
        <Col sm={1} onClick={() => onImageClick(gi29)}>
          <img src={gi29} alt="gi1 " className="graphic-images" />
        </Col>
        <Col sm={1} onClick={() => onImageClick(gi30)}>
          <img src={gi30} alt="gi1 " className="graphic-images" />
        </Col>
        <Col sm={1} onClick={() => onImageClick(gi31)}>
          <img src={gi31} alt="gi1 " className="graphic-images" />
        </Col>
        <Col sm={1} onClick={() => onImageClick(gi32)}>
          <img src={gi32} alt="gi1 " className="graphic-images" />
        </Col>
        <Col sm={1} onClick={() => onImageClick(gi33)}>
          <img src={gi33} alt="gi1 " className="graphic-images" />
        </Col>
        <Col sm={1} onClick={() => onImageClick(gi34)}>
          <img src={gi34} alt="gi1 " className="graphic-images" />
        </Col>
        <Col sm={1} onClick={() => onImageClick(gi35)}>
          <img src={gi35} alt="gi1 " className="graphic-images" />
        </Col>
      </Row>

      <Row>
        <Col sm={1} onClick={() => onImageClick(gi36)}>
          <img src={gi36} alt="gi1 " className="graphic-images" />
        </Col>
        <Col sm={1} onClick={() => onImageClick(gi37)}>
          <img src={gi37} alt="gi3 " className="graphic-images" />
        </Col>
        <Col sm={1} onClick={() => onImageClick(gi38)}>
          <img src={gi38} alt="gi1 " className="graphic-images" />
        </Col>
        <Col sm={1} onClick={() => onImageClick(gi39)}>
          <img src={gi39} alt="gi1 " className="graphic-images" />
        </Col>
        <Col sm={1} onClick={() => onImageClick(gi40)}>
          <img src={gi40} alt="gi1 " className="graphic-images" />
        </Col>
        <Col sm={1} onClick={() => onImageClick(gi41)}>
          <img src={gi41} alt="gi1 " className="graphic-images" />
        </Col>
        <Col sm={1} onClick={() => onImageClick(gi42)}>
          <img src={gi42} alt="gi1 " className="graphic-images" />
        </Col>
        <Col sm={1} onClick={() => onImageClick(gi43)}>
          <img src={gi43} alt="gi1 " className="graphic-images" />
        </Col>
        <Col sm={1} onClick={() => onImageClick(gi44)}>
          <img src={gi44} alt="gi1 " className="graphic-images" />
        </Col>
        <Col sm={1} onClick={() => onImageClick(gi45)}>
          <img src={gi45} alt="gi1 " className="graphic-images" />
        </Col>
        <Col sm={1} onClick={() => onImageClick(gi46)}>
          <img src={gi46} alt="gi1 " className="graphic-images" />
        </Col>
        <Col sm={1} onClick={() => onImageClick(gi47)}>
          <img src={gi47} alt="gi1 " className="graphic-images" />
        </Col>
      </Row>
      <Row>
        <Col sm={1} onClick={() => onImageClick(gi48)}>
          <img src={gi48} alt="gi1 " className="graphic-images" />
        </Col>
        <Col sm={1} onClick={() => onImageClick(gi49)}>
          <img src={gi49} alt="gi1 " className="graphic-images" />
        </Col>
        <Col sm={1} onClick={() => onImageClick(gi50)}>
          <img src={gi50} alt="gi3 " className="graphic-images" />
        </Col>
        {/* <Col sm={3}>
          <Button className="margin-top" onClick={showHideGraphicsHandler}>
            Remove Graphics
          </Button>
        </Col> */}
      </Row>
    </div>
  );
  const handleEffect = () => {
    if (circleRef2) {
      circleRef2.destroy();

      setCircleRef2();
    }
    setEff("0deg");
    setSkw("0deg");
    setItalic("normal");

    if (circleRef) return;
    const circleType = new CircleType(circleInstance.current)
      .dir(1)
      .radius(520);
    circleType.forceWidth(true);
    console.log(
      circleType,
      " ----------------CirleType consoling here--------------"
    );
    setCircleRef(circleType);
    setCircleRef2();
  };

  const handleEffect2 = () => {
    if (circleRef) {
      circleRef.destroy();

      setCircleRef2();
    }
    setEff("0deg");
    setSkw("0deg");
    setItalic("normal");
    if (circleRef2) return;
    const curve2 = new CircleType(circleInstance.current).dir(-1).radius(520);
    curve2.forceWidth(true);
    setCircleRef2(curve2);
    setCircleRef();
    // new CircleType(circleInstance.current).dir(-1).radius(390);

    // if (!data[currStep])
  };
  // console.log(circleInstance.current, "cojaksdjaksdjaksdjaksjdkadjaksdjk");
  const handlecancelEffs = () => {
    if (circleRef) {
      circleRef.destroy();

      setCircleRef();
    }
    if (circleRef2) {
      circleRef2.destroy();

      setCircleRef2();
    }
    setEff("0deg");
    setSkw("0deg");

    setItalic("normal");
  };
  const handleEff = (currdeg, skewdeg) => {
    setItalic("normal");

    if (circleRef) {
      circleRef.destroy();

      setCircleRef();
    }
    if (circleRef2) {
      circleRef2.destroy();

      setCircleRef2();
    }

    setEff(currdeg);
    setSkw(skewdeg);
  };

  const handleItalicEff = () => {
    setEff("0deg");
    setSkw("0deg");
    if (circleRef) {
      circleRef.destroy();

      setCircleRef();
      setCircleRef2();
    }
    if (circleRef2) {
      circleRef2.destroy();

      setCircleRef2();
      setCircleRef();
    }
    if (!circleRef) {
      setCircleRef();
      setCircleRef2();
    }
    if (!circleRef2) {
      setCircleRef2();
      setCircleRef();
    }
    setItalic("italic");
  };
  const tabsCSS = {
    border: "1px solid gray",
    margin: "5px 0px 5px 5px",
    backgroundColor: "white",
    boxShadow: "3px 3px 10px 6px #e9e9ed ",
    // marginTop: "20px",
  };
  const removeBorder = () => {
    if (applyborderColor === true) {
      setApplyborderColor(false);
    }
  };

  const removeGradient = () => {
    if (grclr === true) {
      setgrclr(false);
    }
  };
  const removeLetterColor = () => {
    setFontColor("black");
  };
  // const fontFamilyHandler = (nextFont) => {
  //   setFont(nextFont);
  // };
  // const dpivalue = window.devicePixelRatio;

  // console.log(dpivalue, "--------------------Value consoling here------------");
  // const Samplewidth = useTextWidth({
  //   text: sampleText,
  // });
  // const Samplewidth = getTextWidth(sampleText, "20px");
  // console.log(Samplewidth, "--------------width showing here--------------");
  return (
    <Container>
      <div
        className="show-text"
        style={{
          backgroundImage: `url(${backGroundImg})`,
          // display: "inline-block",CC
          // overflowX: "",
          // overflowY: "inherit",
          // backgroundColor: selectBgColor,
        }}
      >
        <div
          style={{
            // display: "inline-block",
            padding: "33px",
            backgroundColor: selectBgColor,
            textAlign: alignment,
            backgroundPosition: graphicsAlignment,
            backgroundImage: setBackgroundImageGraphics(),
            backgroundRepeat: "no-repeat",
            backgroundSize: "120px",
          }}

          // className="background-clr"
          // style={{
          //   backgroundPosition: graphicsAlignment,
          //   backgroundImage: setBackgroundImageGraphics(),
          //   backgroundRepeat: "no-repeat",
          //   //backgroundSize:'80px 105px',

          //   // backgroundImage: setBackgroundImageType(),
          // }}
        >
          {/* {img === true && (
            <img
              src={img}
              style={{
                // backgroundImage: setBackgroundImageGraphics(),
                backgroundColor: selectBgColor,
                backgroundPosition: graphicsAlignment,
                backgroundRepeat: "no-repeat",
                height: 100,
                width: 100,
              }}
              alt=""
            />
          )} */}
          <div ref={priceInstance} style={{ display: "inline-block" }}>
            <h1
              className="show-text-font apply-font color"
              ref={circleInstance}
              // ref={refwidth}
              style={{
                // position: "absolute",
                // wordWrap: "break-word",
                // overflowWrap: "break-word",
                // display: "inline-block",
                color: grclr === false && fontColor,
                textShadow: `${shadow} ${shadowColor}`,
                WebkitTextStroke: applyborderColor === true && "1px",
                WebkitTextStrokeColor: applyborderColor === true && borderColor,
                WebkitBackgroundClip: grclr === true && "text",
                WebkitTextFillColor: grclr === true && "transparent",
                fontWeight: bold,
                letterSpacing: spacing,
                fontStyle: italic,
                WebkitTransform: `rotate(${Eff}) skew(${skw})`,
                backgroundImage: setBackgroundImageType(),
                maxWidth: "998px",
                overflowWrap: "break-word",

                //             ax-width: 993px;
                // overflow-wrap: break-word;
                // overflowBlock: "initial",
              }}
              // dangerouslySetInnerHTML={{ __html: sampleHtmlText }}
            >
              {" "}
              {/* {left == true && imgLeft && (
                <img
                  src={img}
                  style={{
                    // backgroundImage: setBackgroundImageGraphics(),
                    backgroundColor: selectBgColor,
                    backgroundPosition: graphicsAlignment,
                    backgroundRepeat: "no-repeat",
                    height: 100,
                    width: 100,
                  }}
                  alt=""
                />
              )} */}
              {sampleText}
              {/* {right == true && imgRight && (
                <img
                  src={img}
                  style={{
                    // backgroundImage: setBackgroundImageGraphics(),
                    backgroundColor: selectBgColor,
                    backgroundPosition: graphicsAlignment,
                    backgroundRepeat: "no-repeat",
                    height: 100,
                    width: 100,
                  }}
                  alt=""
                />
              )} */}
            </h1>
            {/* {imgBottom && (
              <img
                src={img}
                style={{
                  // backgroundImage: setBackgroundImageGraphics(),
                  backgroundColor: selectBgColor,
                  backgroundPosition: graphicsAlignment,
                  backgroundRepeat: "no-repeat",
                  height: 100,
                  width: 100,
                }}
                alt=""
              />
            )} */}
          </div>
        </div>
      </div>
      <Box>
        <TabContext value={value}>
          <Box
            style={{ marginTop: "10px" }}
            sx={{ borderBottom: 1, borderColor: "divider" }}
          >
            <TabList
              onChange={handleTabChange}
              aria-label=""
              style={{ backgroundColor: "#f7f7f8" }}
            >
              <Tab
                className="headings-center fonthide "
                icon={<FormatSizeIcon fontSize="large" />}
                iconPosition="start"
                label="Text & Size"
                value="1"
                style={tabsCSS}
              />
              <Tab
                className="headings-center fonthide "
                icon={<TextFormatIcon fontSize="large" color="primary" />}
                iconPosition="start"
                label="Pick Font"
                value="2"
                style={tabsCSS}
              />
              <Tab
                className="headings-center fonthide"
                icon={<ColorLensIcon fontSize="large" color="secondary" />}
                iconPosition="start"
                label="Coloring"
                value="3"
                style={tabsCSS}
              />

              <Tab
                className="headings-center fonthide"
                icon={
                  <FormatPaintIcon fontSize="large" sx={{ color: red[500] }} />
                }
                iconPosition="start"
                label="Graphics"
                value="4"
                style={tabsCSS}
              />
              <Tab
                className="headings-center fonthide"
                icon={<VignetteIcon fontSize="large" color="action" />}
                iconPosition="start"
                label="Effects"
                value="5"
                style={tabsCSS}
              />
              <Tab
                className="headings-center fonthide"
                icon={<ShoppingCartIcon fontSize="large" color="primary" />}
                iconPosition="start"
                label={<span>Add To Cart </span>}
                value="6"
                style={tabsCSS}
              />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Container className="main-container">
              <Row>
                <Col sm={4}>
                  <h1 className="headings-left mob-heading">
                    <ClearAllIcon fontSize="large" color="primary" /> Enter Text
                  </h1>
                  <>
                    <Form.Control
                      className="textarea style-box-shadow"
                      onChange={changeInput}
                      value={sampleText}
                      placeholder={"Your Message Here"}
                      // onKeyPress={keypressInput}
                      as="textarea"
                      rows={3}
                    />
                  </>
                </Col>

                <Col sm={4} className="mob-mt">
                  <>
                    <FormatAlignLeftIcon
                      className="align-icon"
                      fontSize="large"
                      color="primary"
                      onClick={() => handleChangeAlignment("left")}
                    />
                    <FormatAlignCenterIcon
                      className="align-icon"
                      fontSize="large"
                      color="primary"
                      onClick={() => handleChangeAlignment("center")}
                    />
                    <FormatAlignRightIcon
                      className="align-icon"
                      fontSize="large"
                      color="primary"
                      onClick={() => handleChangeAlignment("right")}
                    />

                    <div className="btn-size">
                      <h1 className="headings-center">
                        {" "}
                        Preview Background <AttributionIcon />
                      </h1>

                      <select
                        className="form-control font-size style-box-shadow"
                        value={selectBgColor}
                        onChange={handleChangeBgColor}
                      >
                        <option className="font-size">White</option>
                        <option>Blue</option>
                        <option>Black</option>
                        <option>Green</option>
                        <option>Red</option>
                        <option>Yellow</option>
                        <option value={transpaerntImage}>Transparent</option>
                      </select>
                    </div>
                  </>
                </Col>
                <Col sm={4}>
                  <div className="margin-top-xs">
                    <HeightWidth height={heightInches} width={widthInches} />
                  </div>
                </Col>
              </Row>
            </Container>
          </TabPanel>
          <TabPanel value="2">
            <Container className="main-container">
              <Row>
                <Col sm={4}>
                  <>
                    <h1 className="headings-left mob-heading margin-top-xs ">
                      <TextRotationAngleupIcon color="primary" />
                      Select a Font Style
                    </h1>
                    <Row>
                      <FontPicker
                        families={[
                          "Montez",
                          "Lobster",
                          "Impact",
                          "Josefin Sans",
                          "Shadows Into Light",
                          "Pacifico",
                          "Amatic SC",
                          "Orbitron",
                          "Rokkitt",
                          "Righteous",
                          "Dancing Script",
                          "Bangers",
                          "Chewy",
                          "Sigmar One",
                          "Architects Daughter",
                          "Abril Fatface",
                          "Covered By Your Grace",
                          "Kaushan Script",
                          "Gloria Hallelujah",
                          "Satisfy",
                          "Lobster Two",
                          "Comfortaa",
                          "Cinzel",
                          "Courgette",
                        ]}
                        className="font-family-picker mob-heading"
                        apiKey="AIzaSyDPtqHOkdnMVZb6wrHcyz1Mwyn0Au-HCbI"
                        activeFontFamily={activeFontFamily}
                        onChange={(nextFont) => handlefontChng(nextFont)}
                      />
                    </Row>
                  </>
                </Col>

                <Col sm={4}>
                  <h1 className="headings-center mob-mt margin-top-xs tab-mt">
                    Text Decoration
                  </h1>
                  <Row>
                    <Col sm={3}>
                      <Form.Check
                        className="check-box checkbox-bold"
                        type="checkbox"
                        label="Bold"
                        onClick={changebold}
                        checked={bold === "bold"}
                      />
                    </Col>
                    <Col sm={9}>
                      {" "}
                      <Form.Check
                        className="check-box same-height "
                        type="checkbox"
                        label="All Fonts Same Height"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={6}>
                      {" "}
                      <Form.Check
                        className="check-box checkbox-r-italic"
                        type="checkbox"
                        label="Slant Right"
                        onClick={changeitalic}
                        checked={italic === "italic"}
                      />
                    </Col>
                    <Col sm={6}>
                      {" "}
                      <Form.Check
                        className="check-box label-test"
                        type="checkbox"
                        onClick={() => changeslantitalic()}
                        label=" Slant Left "
                        checked={skw === "20deg"}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col sm={4}>
                  <div className="margin-top-xs">
                    <HeightWidth height={heightInches} width={widthInches} />
                  </div>
                </Col>
              </Row>
            </Container>
          </TabPanel>
          <TabPanel value="3">
            <Container className="main-container">
              <Row>
                <Col sm={8}>
                  <Container>
                    <Row>
                      <Col>
                        <Row>
                          <h1 className="heading-3 text-left mob-mt mob-heading tab-heading ">
                            <BrushIcon fontSize="small" color="primary" />{" "}
                            Letter Color
                            <img
                              src={none}
                              alt="gi1 "
                              className="font-none cursor-pointer"
                              onClick={removeLetterColor}
                            />
                          </h1>
                        </Row>
                        <CompactPicker
                          colors={[
                            "#000000",
                            "#03bb85",
                            "#00FF00",
                            "#30D5C8",
                            "#0000FF",
                            "#00008B",
                            "#FF0000",
                            "#8B0000",
                            "#800020",
                            "#FFC0CB",
                            "#800080",
                            "#808080",
                            "#FFFF00",
                            "#ffdf00",
                            "#f8e032",
                            "#FFA500",

                            "#FFD700",
                            "#FFFFFF",
                          ]}
                          color={fontColor}
                          onChangeComplete={handleChangeFontColor}
                        />
                      </Col>

                      <Col>
                        <Row>
                          <h1 className="heading-3 text-left mob-mt mob-heading tab-heading tab-mt">
                            <BorderOuterIcon fontSize="small" />
                            Border{" "}
                            <img
                              src={none}
                              alt="gi1 "
                              className="border-none cursor-pointer"
                              onClick={removeBorder}
                            />
                          </h1>
                        </Row>
                        <CompactPicker
                          colors={[
                            "#000000",
                            "#03bb85",
                            "#00FF00",
                            "#30D5C8",
                            "#0000FF",
                            "#00008B",
                            "#FF0000",
                            "#8B0000",
                            "#800020",
                            "#FFC0CB",
                            "#800080",
                            "#808080",
                            "#FFFF00",
                            "#ffdf00",
                            "#f8e032",
                            "#FFA500",

                            "#FFD700",
                            "#FFFFFF",
                          ]}
                          color={borderColor}
                          onChangeComplete={handleChangeBorderColor}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h1 className="heading-3 text-left margin-top mob-mt mob-heading tab-heading tab-mt">
                          <GradientIcon
                            fontSize="small"
                            sx={{ color: pink[500] }}
                          />{" "}
                          Gradient
                          <img
                            src={none}
                            alt="gi1 "
                            className="gradient-none cursor-pointer"
                            onClick={removeGradient}
                          />
                        </h1>
                        <CompactPicker
                          colors={[
                            "#000000",
                            "#03bb85",
                            "#00FF00",
                            "#30D5C8",
                            "#0000FF",
                            "#00008B",
                            "#FF0000",
                            "#8B0000",
                            "#800020",
                            "#FFC0CB",
                            "#800080",
                            "#808080",
                            "#FFFF00",
                            "#ffdf00",
                            "#f8e032",
                            "#FFA500",

                            "#FFD700",
                            "#FFFFFF",
                          ]}
                          color={gradientColor}
                          onChangeComplete={handleChangeGradientColor}
                        />
                      </Col>
                      <Col>
                        <h1 className="heading-3 text-left margin-top mob-mt mob-heading tab-heading tab-mt">
                          <WbShadeIcon fontSize="small" color="action" /> Drop
                          Shadow
                        </h1>
                        <div className="text-center  margin-bottom ">
                          <CircleIcon
                            className="align-icon"
                            onClick={() => handleShadow(`0px 0px`)}
                          />
                          <CircleIcon
                            className="align-icon shadow-effects-left"
                            onClick={() => handleShadow(`-4px -4px`)}
                          />
                          <CircleIcon
                            className="align-icon shadow-effects-top"
                            onClick={() => handleShadow(`-4px 4px`)}
                          />
                          <CircleIcon
                            className="align-icon shadow-effects-bottom"
                            onClick={() => handleShadow(`4px 4px`)}
                          />
                          <CircleIcon
                            className="align-icon shadow-effects-right"
                            onClick={() => handleShadow(`4px -4px`)}
                          />
                        </div>
                        <CompactPicker
                          colors={[
                            "#000000",
                            "#03bb85",
                            "#00FF00",
                            "#30D5C8",
                            "#0000FF",
                            "#00008B",
                            "#FF0000",
                            "#8B0000",
                            "#800020",
                            "#FFC0CB",
                            "#800080",
                            "#808080",
                            "#FFFF00",
                            "#ffdf00",
                            "#f8e032",
                            "#FFA500",
                            "#FFD700",
                            "#FFFFFF",
                          ]}
                          color={shadowColor}
                          onChangeComplete={handleChangeShadowColor}
                        />
                      </Col>
                    </Row>
                  </Container>
                </Col>

                <Col sm={4}>
                  <div className="margin-top-xl ">
                    <HeightWidth height={heightInches} width={widthInches} />
                  </div>
                </Col>
              </Row>
            </Container>
          </TabPanel>
          <TabPanel value="4">
            <Container className="main-container">
              <Row>
                <Col sm={3}>
                  <h1 className="headings-left margin-top-xs">
                    {" "}
                    <GraphicEqIcon fontSize="small" sx={{ color: red[500] }} />
                    Graphics Position
                  </h1>
                  <select
                    className="form-control font-size style-box-shadow"
                    value={graphicsAlignment}
                    onChange={handleImgAlignment}
                  >
                    <option value="left">Left</option>
                    <option value="center">Center</option>
                    <option value="right">Right</option>
                    <option value="top">Top</option>
                    <option value="bottom">Bottom</option>
                  </select>
                </Col>
                <Col sm={5}>
                  <></>
                </Col>
                <Col sm={4}>
                  <div className="margin-top-xs">
                    <HeightWidth height={heightInches} width={widthInches} />
                  </div>
                </Col>
              </Row>
              <Row>
                {/* <Col>{showImages === true ? <ImgGraphics /> : false}</Col> */}
                <Col sm={12}>
                  <div className="margin-top-xs">
                    <ImgGraphics />
                  </div>
                </Col>
              </Row>
            </Container>
          </TabPanel>

          <TabPanel value="5">
            <Container className="main-container">
              <Row>
                <Col sm={4}>
                  <h1 className="headings-left curser-pointer mob-heading">
                    <VignetteIcon fontSize="small" color="action" />
                    Decal Effects
                  </h1>

                  <Col>
                    <Row>
                      <Col sm={3}>
                        <img
                          src={reset}
                          className="effect-images"
                          onClick={() => handlecancelEffs()}
                          alt="EffectImages"
                        />
                      </Col>
                      <Col sm={3}>
                        <img
                          src={curveU}
                          className="effect-images"
                          onClick={() => handleEffect(``)}
                          alt="EffectImages"
                        />
                      </Col>
                      <Col sm={3}>
                        <img
                          src={curveUD}
                          className="effect-images"
                          alt="EffectImages"
                          onClick={() => handleEff(`5deg`, "0deg")}
                        />
                      </Col>
                      <Col sm={3}>
                        <img
                          src={curveSR}
                          className="effect-images"
                          onClick={handleItalicEff}
                          alt="EffectImages"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={3}>
                        <img
                          src={curveSL}
                          className="effect-images"
                          onClick={() => handleEff(`0deg`, "10deg")}
                          alt="EffectImages"
                        />
                      </Col>

                      <Col sm={3}>
                        <img
                          src={curveD}
                          className="effect-images"
                          onClick={() => handleEffect2(``)}
                          alt="EffectImages"
                        />
                      </Col>
                      <Col sm={3}>
                        {" "}
                        <img
                          src={curveDU}
                          className="effect-images"
                          onClick={() => handleEff(`-5deg`, "0deg")}
                          alt="EffectImages"
                        />
                      </Col>
                      <Col sm={3}> </Col>
                    </Row>
                  </Col>
                </Col>
                <Col sm={4}></Col>
                <Col sm={4}>
                  <HeightWidth height={heightInches} width={widthInches} />
                </Col>
              </Row>
            </Container>
          </TabPanel>

          <TabPanel value="6">Add to cart</TabPanel>
        </TabContext>
      </Box>
    </Container>
  );
}
