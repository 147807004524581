import { Row, Col } from "react-bootstrap";
import CropIcon from "@mui/icons-material/Crop";
import "./textnsize.css";

const HeightWidth = (props) => {
  // console.log(props.width, " props consoling here =============");
  let cost = (props.height + props.width) * 0.71;
  return (
    <div>
      <Row>
        <Col sm={12}>
          <h1 className="headings-left mob-mt mob-heading mb-2">
            Price ${cost.toFixed(2)}
          </h1>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <h1 className="headings-left mob-mt mob-heading">
            <CropIcon fontSize="small" color="primary" /> Capital Letter Height
          </h1>
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <div className="input-group style-box-shadow">
            <span className="input-group-text">Width</span>
            <input
              type="text"
              className="form-control"
              placeholder={props.width}
            />
            <div className="input-group-append"></div>
          </div>
        </Col>
        <Col sm={6}>
          <div className="input-group style-box-shadow">
            <span className="input-group-text">Height</span>
            <input
              type="text"
              className="form-control"
              placeholder={props.height}
            />
            <div className="input-group-append"></div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default HeightWidth;
